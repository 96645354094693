 
  {#if rotta_pronta}
    <main
        id="rotta"
        class:schermo-intero={schermo_intero}
        transition:fade={{ duration: 400 }}
        on:introstart
    		on:intrond
    		on:outrostart
    		on:outroend>
      {#if schermo_intero}
        <div
            class="propagatore-evento-schermo-intero"
            transition:fade={{ duration: 400 }}
            on:outroend={propaga_chiusura_schermo_intero}
            on:introend={propaga_apertura_schermo_intero}/>
      {/if}
      <div class="contenuto">
        <slot/>
      </div>
    </main>
  {:else}
    <Caricatore/>
  {/if}


<style>        
  :global(main#rotta) {
    width:        calc(100vw - 258px);
    height:       100vh;
    margin-left:  258px;
    padding:      0;
    transition:   width 0.4s ease, margin 0.4s ease;
    position:     absolute;
  }

  /* Barra laterale chiusa. */
  :global(div#barra-laterale.chiusa + main#rotta) {
    width:        100vw;
    margin-left:  0;
  }

  /* Schermo intero. */
  :global(main#rotta.schermo-intero) {
    width:        100vw;
    height:       100vh;
    position:     fixed;
    top:          0;
    left:         0;
    margin:       0;
    padding:      0;
    z-index:      10;
  }
  :global(main#rotta > div.contenuto) {
    height:         100%;
    display:        flex;
    flex-direction: column;
    position:       relative;
  }
  :global(main#rotta > div.propagatore-evento-schermo-intero) {
    height:         0;
  }
</style>

<script>
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { onMount } from "svelte"
  import { fade } from "svelte/transition"

  export let schermo_intero = false
  export let richiede_autenticazione = true

  let rotta_pronta = richiede_autenticazione ? false : true

  ////
  // Metodo per autenticare l'utente corrente.
  function verifica_utente_corrente() {
    if ($utente_corrente != null) return rotta_pronta = true
    utente_corrente.slogga(true)
  }

  // Propagazione eventi.
  function propaga_apertura_schermo_intero() {
    window.dispatchEvent(new CustomEvent("schermo-intero-aperto"))
  }
  function propaga_chiusura_schermo_intero() {
    window.dispatchEvent(new CustomEvent("schermo-intero-chiuso"))
  }

  // Quando prondo, valuta l'autenticazione.
  if (richiede_autenticazione) onMount(verifica_utente_corrente)
</script>
