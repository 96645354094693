
  <div
      class="selezione apertura-{apertura}"
      class:piana={piana}
      class:errore={errore}
      class:disabilitata={disabilitata}
      class:con-etichetta={etichetta != null}
      on:click={visualizza_opzioni}>

    <slot/>

    {#if etichetta != null}
      <label for="">{etichetta}</label>
    {/if}

    <div
        class="contenitore-valore"
        class:messo-a-fuoco={opzioni_visualizzate}
        bind:this={elemento_valore}>
      <div
          class="valore-selezionato"
          class:segnaposto={valore == null}>
        {#if remota && in_caricamento && valore != null}
          <Caricatore piccolo={true}/>
        {:else if valore != null && opzione_selezionata != null}
          <slot name="valore" {opzione_selezionata}>
            {opzione_selezionata.testo}
          </slot>
        {:else if segnaposto != null}
          {segnaposto}
        {:else}
          {t.seleziona_un_valore[$l]}
        {/if}
      </div>
  
      {#if cancellabile && valore != null && valore !== ""}
        <div class="contenitore-cancellazione">
          <Bottone
              piccolo={true}
              variante="piano"
              disabilitato={disabilitata}
              on:click={cancella_valore}>
            <Icona tipo="chiudi"/>
          </Bottone>
        </div>
      {/if}
      <div class="contenitore-icona">
        <Icona bind:tipo={icona}/>
      </div>
    </div>

    {#if opzioni_visualizzate}
      <div
          class="contenitore-opzioni"
          transition:fade={{ duration: 150 }}>
        {#if ricercabile}
          <div
              class="ricerca"
              bind:this={elemento_ricerca}>
            <Inserimento
                piano={true}
                tipo="testuale"
                icona="ricerca"
                metti_a_fuoco={true}
                attributi={attributi}
                disabilitato={disabilitata}
                segnaposto={segnaposto_ricerca != null ? segnaposto_ricerca : t.cerca_fra_le_opzioni[$l]}
                bind:valore={testo_ricerca}
                on:input
                on:keydown/>
          </div>
        {:else if ricercabile_senza_icona}
          <div
              class="ricerca"
              bind:this={elemento_ricerca}>
            <Inserimento
                piano={true}
                tipo="testuale"
                metti_a_fuoco={true}
                attributi={attributi}
                disabilitato={disabilitata}
                segnaposto={segnaposto_ricerca != null ? segnaposto_ricerca : t.cerca_fra_le_opzioni[$l]}
                bind:valore={testo_ricerca}
                on:input
                on:keydown/>
          </div>
        {/if}
        {#if in_caricamento}
          <Caricatore piccolo={true}/>
        {:else if opzioni_ricercate.length > 0}
          {#each opzioni_ricercate as opzione, indice}
            <div
                class="opzione"
                class:disabilitata={opzione.disabilitata}
                on:click|preventDefault={evento => imposta_valore(opzione)}>
              <slot name="opzione" {opzione}>
                {opzione.testo}
              </slot>
            </div>
          {/each}
        {:else}
          <div class="segnaposto">
            {t.nessuna_opzione_disponibile[$l]}
          </div>
        {/if}
      </div>
    {/if}

    {#if errore != null}
      <div class="errore">
        {errore}
      </div>
    {/if}

    <slot name="azioni"/>
  </div>

  <svelte:window on:click={determina_se_pressione_esterna}/>


<style>        
  :global(div.selezione) {
    position:       relative;
    margin-bottom:  14px;
    cursor:         pointer;
  }

  /* Etichetta */
  :global(div.selezione > label) {
    display:        flex;
    color:          var(--colore-testo-leggero);
    font-weight:      400;
    line-height:      30px;
    font-size:        12px;
    margin-bottom:    0px;
    margin-left:      0px;
    cursor:         default;
  }
  :global(div.selezione.piana > label) {
    padding-left:   7px;
  }

  /* Valore selezionato */
  :global(div.selezione > div.contenitore-valore) {
    display:        flex;
    align-items:    center;
    width:          100%;
    height:         42px;
    background:     var(--colore-bianco);
    color:          var(--colore-testo);
    border:         1px solid var(--colore-bordo);
    font-family:    "Inter", sans-serif;
    font-weight:    400;
    font-size:      14px;
    line-height:    24px;
    padding:        9px 12px;
    margin:         0;
    white-space:    nowrap;
    transition:     border-color 0.3s ease-in-out;
    border-radius:  5px;
    position:       relative;
  }
  :global(div.selezione.disabilitata > div.contenitore-valore) {
    border:           none;
    background-color: var(--colore-disabilitato);
    cursor:           not-allowed;
  }
  :global(div.selezione > div.contenitore-valore.messo-a-fuoco) {
    border:         1px solid var(--colore-primario);
  }
  :global(div.selezione > div.contenitore-valore > div.valore-selezionato) {
    width:          100%;
    overflow:       hidden;
    text-overflow:  ellipsis;
    margin-right:   15px;
  }
  :global(div.selezione > div.contenitore-valore > div.valore-selezionato.segnaposto) {
    color:          var(--colore-segnaposto);
    font-style:     italic;
  }
  :global(div.selezione.piana > div.contenitore-valore) {
    border:         1px solid transparent;
    background:     inherit;
  }
  :global(div.selezione.piana > div.contenitore-valore.messo-a-fuoco) {
    border:         1px solid transparent;
  }
  :global(div.selezione > div.contenitore-valore div.contenitore-icona .icona) {
    transform:      rotate(0deg);
    transition:     transform .3s ease-in-out;
  }
  :global(div.selezione > div.contenitore-valore.messo-a-fuoco div.contenitore-icona .icona) {
    transform:      rotate(180deg);
  }

  /* Icona */
  :global(div.selezione > div.contenitore-valore > div.contenitore-icona),
  :global(div.selezione > div.contenitore-valore > div.contenitore-cancellazione) {
    display:          flex;
    justify-content:  center;
    align-items:      center;
    position:         absolute;
    top:              0;
    right:            0;
    width:            33px;
    height:           42px;
  }
  :global(div.selezione > div.contenitore-valore > div.contenitore-cancellazione) {
    margin-right:     33px;
  }
  :global(div.selezione > div.contenitore-valore > div.contenitore-cancellazione > button) {
    width:            40px;
    height:           40px;
    border-radius:    0;
  }

  /* Opzioni */
  :global(div.selezione > div.contenitore-opzioni) {
    position:         absolute;
    right:            0;
    left:             0;
    max-height:       355px;
    background:       var(--colore-bianco);
    overflow:         auto;
    border-left:      1px solid var(--colore-bordo);
    border-right:     1px solid var(--colore-bordo);
    border-bottom:    1px solid var(--colore-bordo);
    z-index:          8;
  }
  :global(div.selezione.apertura-sotto > div.contenitore-opzioni) {
    top:              47px;
  }
  :global(div.selezione.con-etichetta.apertura-sotto > div.contenitore-opzioni) {
    top:              calc(47px + 21.75px);
  }
  :global(div.selezione.apertura-sopra > div.contenitore-opzioni) {
    bottom:           100%;
  }
  :global(div.selezione.piana > div.contenitore-opzioni) {
    border-top:       1px solid var(--colore-bordo);
  }
  :global(div.selezione > div.contenitore-opzioni > div.segnaposto),
  :global(div.selezione > div.contenitore-opzioni > div.opzione) {
    display:          flex;
    align-items:      center;
    height:           42px;
    overflow:         hidden;
    text-overflow:    ellipsis;
    white-space:      nowrap;
    padding:          0 7px;
  }
  :global(div.selezione > div.contenitore-opzioni > div.opzione) {
    cursor:           pointer;
  }
  :global(div.selezione > div.contenitore-opzioni > div.segnaposto) {
    color:            var(--colore-segnaposto);
    font-style:       italic;
  }
  :global(div.selezione > div.contenitore-opzioni > div.opzione:hover) {
    background:       var(--colore-sorvolo);
  }
  :global(div.selezione > div.contenitore-opzioni > div.opzione.disabilitata) {
    cursor:           not-allowed;
    color:            var(--colore-segnaposto);
  }
  :global(div.selezione > div.contenitore-opzioni > div.opzione.disabilitata:hover) {
    background:       var(--colore-bianco);
  }

  /* Ricerca su opzioni */
  :global(div.selezione > div.contenitore-opzioni > div.ricerca) {
    position:         sticky;
    top:              0;
    background-color: var(--colore-bianco);
  }
  :global(div.selezione > div.contenitore-opzioni > div.ricerca > div.inserimento) {
    margin-bottom:    0;
    border-bottom:    1px solid var(--colore-bordo);
  }

  /* Errore. */
  :global(div.selezione.errore > div.contenitore-valore) {
    border-color:     var(--colore-rosso);
  }
  :global(div.selezione.errore > div.errore) {
    margin-left:      1.75px;
    color:            var(--colore-rosso);
    font-size:        12px;
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Caricatore from "@/base/componenti/Caricatore.svelte"
  import Inserimento from "@/base/componenti/Inserimento.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { fade } from "svelte/transition"
  import { createEventDispatcher } from "svelte"
  
  export let valore = null
  export let errore = null
  export let opzioni = []
  export let etichetta = null
  export let disabilitata = false
  export let remota = false
  export let in_caricamento = false
  export let cancellabile = false
  export let ricercabile = false
  export let ricercabile_senza_icona = false
  export let piana = false
  export let apertura = "sotto"
  export let icona = "espandi"
  export let attributi = {}
  export let segnaposto = null
  export let segnaposto_ricerca = null

  const t = {
    seleziona_un_valore: {
      it: `Seleziona un valore`,
      en: `Please select`,
      de: `Bitte auswählen`
    },
    nessuna_opzione_disponibile: {
      it: `Nessuna opzione disponibile`,
      en: `No available options`,
      de: `Keine verfügbaren Optionen`
    },
    cerca_fra_le_opzioni: {
      it: `Cerca fra le opzioni`,
      en: `Search in options`,
      de: `Suchen Sie in Optionen`
    } }

  let propaga = createEventDispatcher()
  let elemento_valore = null
  let elemento_ricerca = null
  let opzione_selezionata = null
  let opzioni_visualizzate = false
  let opzioni_ricercate = opzioni
  let testo_ricerca = ""

  // Converte le opzioni in oggetto testo/valore.
  opzioni = opzioni.map(opzione => {
    if (Object.keys(opzione).include("valore")) return opzione
    return { testo: opzione, valore: opzione }
  })

  ////
  // Visualizza le opzioni.
  function visualizza_opzioni() {
    if (disabilitata) return
    if (remota) return propaga("open")
    opzioni_visualizzate = true
  }

  ////
  // Imposta il valore.
  function imposta_valore(opzione) {
    if (opzione.disabilitata) return
    valore = opzione.valore
  }

  ///
  // Cancella il valore.
  function cancella_valore() {
    valore = null
    opzione_selezionata = null
    propaga("change", { valore: valore })
  }

  ////
  // Seleziona l'opzione.
  function seleziona_opzione() {
    if (valore == null) return opzione_selezionata = null
    if (opzione_selezionata && opzione_selezionata.valore == valore) return

    opzione_selezionata = opzioni.find(opzione => opzione.valore == valore)
    opzioni_visualizzate = false
    if (!remota) testo_ricerca = ""
    propaga("change", { valore: valore, opzione_selezionata })
  }

  ////
  // Ricerca fra le opzioni.
  function ricerca_fra_opzioni() {
    if (remota) return opzioni_ricercate = opzioni

    opzioni_ricercate = opzioni.filter((opzione) => {
      if (testo_ricerca == null || testo_ricerca == "") return true
      if (opzione.testo == null) return false
      return opzione.testo.match(new RegExp(`.*${testo_ricerca}.*`, "i")) != null
    })

    return opzioni_ricercate
  }

  ////
  // Determina se è stata fatta una pressione su un'area
  // esterna alla selezione. Se è così, nascondi le opzioni.
  function determina_se_pressione_esterna(evento) {
    if (disabilitata) return
    if (evento == null || evento.target == null) return

    let pressione_su_valore, pressione_su_ricerca
    if (elemento_valore != null) pressione_su_valore = elemento_valore.contains(evento.target)
    if (elemento_ricerca != null) pressione_su_ricerca = elemento_ricerca.contains(evento.target)

    opzioni_visualizzate = pressione_su_valore || pressione_su_ricerca
  }

  // Quando cambia il valore, seleziona l'opzione.
  $: { valore; seleziona_opzione() }
  // Quando cambiano le opzioni, seleziona l'opzione.
  $: { opzioni; seleziona_opzione() }

  // Ricerca fra le opzioni.
  $: if (testo_ricerca != null && opzioni.length > 0) ricerca_fra_opzioni()

  // Reimposta le opzioni se remota.
  $: if (remota && opzioni != null) ricerca_fra_opzioni()
</script>
