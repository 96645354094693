
  <div class="caricatore-contenitore" class:piccolo={piccolo}>

    {#if messaggio != null}
      <span class="messaggio-caricatore">{messaggio}</span>
    {/if}

    <div class="caricatore">
      <div class="barra-1"/>
      <div class="barra-2"/>
      <div class="barra-3"/>
      <div class="barra-4"/>
      <div class="barra-5"/>
      <div class="barra-6"/>
    </div>
  </div>


<style>        
  div.caricatore-contenitore {
    display:          flex;
    justify-content:  center;
    align-items:      center;
    height:           100%;
    flex-grow:        1;
    flex-direction:   column;
  }
  div.caricatore-contenitore.piccolo {
    transform:        scale(0.5);
  }
  div.caricatore-contenitore span.messaggio-caricatore {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  div.caricatore {
    width:    60px;
    height:   50px;
    display:  flex;
  }

  div.caricatore > div {
    width:            8px;
    height:           100%;
    border-radius:    5px;
    margin-left:      2px;
    animation:        caricatore 0.8s infinite ease-in-out;
    background-color: var(--colore-primario);
  }
  div.caricatore > div.barra-2 {
    animation-delay: -0.7s;
  }
  div.caricatore > div.barra-3 {
    animation-delay: -0.6s;
  }
  div.caricatore > div.barra-4 {
    animation-delay: -0.5s;
  }
  div.caricatore > div.barra-5 {
    animation-delay: -0.4s;
  }
  div.caricatore > div.barra-6 {
    animation-delay: -0.3s;
  }
  
  @keyframes caricatore {
    0%, 40%, 100% {
      transform: scaleY(0.05);
    }
    20% {
      transform: scaleY(1.0);
    }
  }
</style>

<script>
  export let piccolo = false
  export let messaggio = null
</script>