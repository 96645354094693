
  <i class="icona icona-{tipo}"/>


<style>
  @font-face {
    font-family:  'icomoon';
    src:  url('/tipografia/icomoon.eot');
    src:  url('/tipografia/icomoon.eot')   format("embedded-opentype"),
          url('/tipografia/icomoon.ttf')   format("truetype"),
          url('/tipografia/icomoon.woff')  format("woff");
    font-weight:  normal;
    font-style:   normal;
  }

  :global([class^="icona-"], [class*=" icona-"]) {
    font-family:    'icomoon' !important;
    font-style:     normal;
    font-weight:    normal;
    font-variant:   normal;
    text-transform: none;
    font-size:      24px;
    line-height:    1;

    -webkit-font-smoothing:   antialiased;
    -moz-osx-font-smoothing:  grayscale;
  }

  :global(.icona.leggera) {
    color: var(--colore-bianco);
  }
  :global(.icona-carica-su:before) {
    content: "\e926";
  }
  :global(.icona-calendario:before) {
    content: "\e90a";
  }
  :global(.icona-occupazione:before) {
    content: "\e923";
  }
  :global(.icona-in-processazione:before) {
    content: "\e928";
  }
  :global(.icona-persona:before) {
    content: "\e921";
  }
  :global(.icona-punto:before) {
    content: "\e910";
  }
  :global(.icona-ricerca:before) {
    content: "\e913";
  }
  :global(.icona-condividi:before) {
    content: "\e924";
  }
  :global(.icona-stella:before) {
    content: "\e90c";
  }
  :global(.icona-supporto:before) {
    content: "\e912";
  }
  :global(.icona-tempo:before) {
    content: "\e929";
  }
  :global(.icona-triangolo-giù:before) {
    content: "\e915";
  }
  :global(.icona-triangolo-su:before) {
    content: "\e916";
  }
  :global(.icona-triangolo-destra:before) {
    content: "\e907";
  }
  :global(.icona-angolare-sinistra:before) {
    content: "\e905";
  }
  :global(.icona-angolare-destra:before) {
    content: "\e906";
  }
  :global(.icona-chiudi:before) {
    content: "\e909";
  }
  :global(.icona-info:before) {
    content: "\e90d";
  }
  :global(.icona-mappa:before) {
    content: "\e91f";
  }
  :global(.icona-fatto:before) {
    content: "\e911";
  }
  :global(.icona-divieto:before) {
    content: "\e92a";
  }
  :global(.icona-duplica:before) {
    content: "\e91e";
  }
  :global(.icona-modifica:before) {
    content: "\e920";
  }
  :global(.icona-puntini-sospensione:before) {
    content: "\e914";
  }
  :global(.icona-esporta:before) {
    content: "\e927";
  }
  :global(.icona-cuore:before) {
    content: "\e90e";
  }
  :global(.icona-filtro:before) {
    content: "\e90b";
  }
  :global(.icona-hotel:before) {
    content: "\e922";
  }
  :global(.icona-spento:before) {
    content: "\e903";
  }
  :global(.icona-acceso:before) {
    content: "\e904";
  }
  :global(.icona-allegato:before) {
    content: "\e925";
  }
  :global(.icona-bloccato:before) {
    content: "\e90f";
  }
  :global(.icona-cestino:before) {
    content: "\e91d";
  }
  :global(.icona-rimuovi:before) {
    content: "\e901";
  }
  :global(.icona-aggiungi:before) {
    content: "\e902";
  }
  :global(.icona-stelle:before) {
    content: "\e91c";
    color: #ffb000;
  }
  :global(.icona-stelle-1:before) {
    content: "\e91b";
    color: #ffb000;
  }
  :global(.icona-stelle-2:before) {
    content: "\e91a";
    color: #ffb000;
  }
  :global(.icona-stelle-3:before) {
    content: "\e919";
    color: #ffb000;
  }
  :global(.icona-stelle-4:before) {
    content: "\e918";
    color: #ffb000;
  }
  :global(.icona-stelle-S:before) {
    content: "\e917";
    color: #ffb000;
  }
  :global(.icona-espandi:before) {
    content: "\e908";
  }
  :global(.icona-menù:before) {
    content: "\e900";
  }
  :global(.icona-freccia-destra:before) {
    content: "\e92b";
  }
  :global(.icona-lucchetto-chiuso:before) {
    content: "\e98f";
  }
  :global(.icona-lucchetto-aperto:before) {
    content: "\e990";
  }
</style>

<script>
  export let tipo = ""
</script>