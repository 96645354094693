
  <section
      id="sfondo-accesso"
      class:schermo-intero={schermo_intero}>
      <div class="sfondo" style="background-image: url({window.risolviImg(sfondo_accesso_hstaff)})"></div>
    </section>


<style>
  :global(section#sfondo-accesso) {
    width:            100%;
    position:         relative;
  }
  :global(section#sfondo-accesso div.sfondo) {
    background-size:  cover;
    height:           100%;
    filter:           grayscale(100%);
  }
  :global(section#sfondo-accesso:after) {
    content:          '';
    display:          block;
    position:         absolute;
    top:              0;
    bottom:           0;
    left:             0;
    right:            0;
    background-color: var(--colore-primario);
    opacity:          0.55;
  }
</style>

<script>
  import sfondo_accesso_hstaff  from "@/base/immagini/sfondi/hstaff.jpg"

  export let schermo_intero = false
</script>
