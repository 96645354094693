
  <div
      class="inserimento {tipo} lato-icona-{lato_icona}"
      class:cancellabile={cancellabile}
      class:con-icona={icona}
      class:errore={errore}
      class:piano={piano}>

    {#if etichetta != null}
      <label for="">
        {etichetta}
        {#if etichetta_piccola != null}
          <small>{etichetta_piccola}</small>
        {/if}
      </label>
    {/if}

    <div class="contenitore-inserimento" bind:this={contenitore}>
      <svelte:component
          this={componenti_per_tipo[tipo]}
          bind:valore={valore}
          {nome}
          {segnaposto}
          {disabilitato}
          {attributi}
          {lunghezza_massima}
          {passo}
          on:keydown
          on:change
          on:input
          on:focus
          on:blur
          on:click/>

      {#if cancellabile && valore != null && valore != ""}
        <div class="contenitore-cancellazione">
          <Bottone
              piccolo={true}
              variante="piano"
              {disabilitato}
              on:click={cancella_inserimento}>
            <Icona tipo="chiudi"/>
          </Bottone>
        </div>
      {/if}

      {#if icona}
        <div class="contenitore-icona">
          <Icona tipo={icona}/>
        </div>
      {/if}

      {#if messaggio != null}
        <span class="messaggio">
          {messaggio}
        </span>
      {/if}

      {#if errore != null}
        <span class="errore">
          {@html errore}
        </span>
      {/if}
    </div>

  </div>


<style>
  :global(div.inserimento) {
    position:         relative;
    margin-bottom:    14px;
  }

  /* Etichetta. */
  :global(div.inserimento > label) {
    display:          inline-block;
    color:            var(--colore-testo);
    font-weight:      400;
    line-height:      30px;
    font-size:        12px;
    margin-bottom:    0px;
    margin-left:      0px;
    cursor:           default;
  }
  :global(div.inserimento > div.contenitore-inserimento){
    position: relative;
  }
  /* Inserimento. */
  :global(div.inserimento > div.contenitore-inserimento > input) {
    display:          flex;
    width:            100%;
    height:           42px;
    background:       var(--colore-bianco);
    color:            var(--colore-testo);
    border:           1px solid var(--colore-bordo);
    font-family:      "Inter", sans-serif;
    font-weight:      400;
    font-size:        14px;
    line-height:      24px;
    padding:          9px 12px;
    margin:           0;
    transition:       border-color 0.3s ease-in-out;
    border-radius:    5px;
  }
  :global(div.inserimento > div.contenitore-inserimento > input:focus) {
    border:           1px solid var(--colore-primario);
    outline:          none;
    box-shadow:       none;
  }
  :global(div.inserimento.piano > div.contenitore-inserimento > input) {
    border:           none;
    background:       inherit;
  }
  :global(div.inserimento > div.contenitore-inserimento > input[disabled]) {
    background-color: var(--colore-disabilitato);
    cursor:           not-allowed;
    color:            var(--colore-secondario)
  }
  :global(div.inserimento > div.contenitore-inserimento > .errore) {
    font-size: 12px;
  }
  :global(div.inserimento > div.contenitore-inserimento > div.contenitore-icona),
  :global(div.inserimento > div.contenitore-inserimento > div.contenitore-cancellazione) {
    display:          flex;
    justify-content:  center;
    align-items:      center;
    position:         absolute;
    top:              1px;
    width:            40px;
    height:           40px;
  }
  :global(div.inserimento > div.contenitore-inserimento > div.contenitore-cancellazione > button) {
    width:            40px;
    height:           40px;
  }

  /* Icona. */
  :global(div.inserimento.con-icona.lato-icona-sinistro > div.contenitore-inserimento > input) {
    padding-left:     42px;
  }
  :global(div.inserimento.con-icona.lato-icona-destro > div.contenitore-inserimento > input) {
    padding-right:    42px;
  }
  :global(div.inserimento.lato-icona-sinistro > div.contenitore-inserimento > div.contenitore-icona) {
    left:             0;
  }
  :global(div.inserimento.lato-icona-destro > div.contenitore-inserimento > div.contenitore-icona) {
    right:            0;
  }

  /* Cancellabile. */
  :global(div.inserimento.cancellabile > div.contenitore-inserimento > input) {
    padding-right:    42px;
  }
  :global(div.inserimento.con-icona.lato-icona-destro.cancellabile > div.contenitore-inserimento > input) {
    padding-right:    94px;
  }
  :global(div.inserimento > div.contenitore-inserimento > div.contenitore-cancellazione) {
    right:            0;
  }
  :global(div.inserimento.con-icona.lato-icona-destro > div.contenitore-inserimento > div.contenitore-cancellazione) {
    right:            42px;
  }

  /* Messaggio. */
  :global(div.inserimento.errore > div.contenitore-inserimento > span.messaggio) {
    margin-left:      1.75px;
    color:            var(--colore-testo-leggero);
  }

  /* Errore. */
  :global(div.inserimento.errore > div.contenitore-inserimento > input) {
    border-color:     var(--colore-rosso);
  }
  :global(div.inserimento.errore > div.contenitore-inserimento > span.errore) {
    margin-left:      1.75px;
    color:            var(--colore-rosso);
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Testuale from "@/base/componenti/inserimenti/Testuale.svelte"
  import Password from "@/base/componenti/inserimenti/Password.svelte"
  import Numero from "@/base/componenti/inserimenti/Numero.svelte"
  import Email from "@/base/componenti/inserimenti/Email.svelte"
  import { onMount } from "svelte"
  import { createEventDispatcher } from "svelte"

  export let tipo = "testuale"
  export let nome = ""
  export let valore = ""
  export let segnaposto = ""
  export let disabilitato = false
  export let cancellabile = false
  export let metti_a_fuoco = false
  export let piano = false
  export let icona = null
  export let lato_icona = "sinistro"
  export let etichetta = null
  export let etichetta_piccola = null
  export let messaggio = null
  export let errore = null
  export let attributi = {}
  export let lunghezza_massima = null
  export let passo = 1

  const propaga = createEventDispatcher()

  let contenitore = null

  ////
  // Componente in base al tipo di inserimento.
  let componenti_per_tipo = {
    testuale:     Testuale,
    password:     Password,
    email:        Email,
    numero:       Numero }
  // telefono:     Telefono,
  // archivio:     Archivio }

  // Cancella il valore dell'inserimento e propaga l'evento.
  function cancella_inserimento() {
    valore = ""
    propaga("input")
  }

  ////
  // Mette a fuoco l'inserimento.
  function metti_a_fuoco_inserimento() {
    if (contenitore == null) return
    let inserimento = contenitore.querySelector("input")
    inserimento.focus()
  }

  // Auto mette a fuoco quando l'elemento è pronto.
  onMount(() => {
    if (metti_a_fuoco) return metti_a_fuoco_inserimento()
  })
</script>
