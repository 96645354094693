
  <Autenticatore logo={logo_completo_semplice} {rotte}>
    <div class="contenuto-barra-laterale">
      <div class="rotte">
        <Collegamento nome_rotta="/">
          {t.strutture[$l]}
        </Collegamento>

        <Collegamento nome_rotta="/codifiche">
          {t.codifiche[$l]}
        </Collegamento>
      </div>
    </div>
  </Autenticatore>


<style>
  /* Barra laterale. */
  :global(div.contenuto-barra-laterale) {
    height:           100%;
    display:          flex;
    flex-direction:   column;
  }

  /* Sfondo principale. */
  :global(main#rotta > div.contenuto) {
    background: var(--colore-sfondo);
  }
  :global(div.contenitore-base) {
    padding:    15px;
    height:     100%;
    width:      100%;
  }

  /* Rotte. */
  :global(div.contenuto-barra-laterale > div.rotte) {
    position:         relative;
  }
  :global(div.contenuto-barra-laterale > div.rotte) {
    flex-grow:        1;
  }
</style>

<script>
  import Collegamento from "@/base/componenti/barra_laterale/Collegamento.svelte"
  import Autenticatore from "@/base/rotte/Autenticatore.svelte"
  import Accesso from "@/best_western/rotte/Accesso.svelte"
  import Codifiche from "@/best_western/rotte/Codifiche.svelte"
  import Strutture from "@/best_western/rotte/Strutture.svelte"
  import logo_completo_semplice from "@/base/immagini/logo-completo-semplice-HB+BW.svg"
  import { avvia_localizzazione } from "@/base/sorgenti/svuby"
  import { localizzazione as l } from "@/base/sorgenti/svuby"

  const t = avvia_localizzazione(dizionario_componente_principale)
  const rotte = {
    "/": Strutture,
    "/codifiche": Codifiche,
    "/accesso": Accesso }
</script>

<script context="module">
  export const dizionario_componente_principale = {
    codifiche: {
      it: `Codifiche`,
      en: ``,
      de: ``
    },
    strutture: {
      it: `Strutture`,
      en: ``,
      de: ``
    },
  }
</script>