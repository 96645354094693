
  <div
      id="barra-laterale"
      class:chiusa={chiusa}
      transition:fade={{ duration: 400 }}>

    {#if !chiusa}
      <div
          class="transizione"
          transition:fade={{ duration: 400 }}
          on:introend={propaga_apertura}
          on:outroend={propaga_chiusura}>
        <div class="logo" class:senza-margini={window.location.hostname == `portal.lab.hbenchmark.it`}>
          <img src={window.risolviImg(logo)} alt="HBenchmark" on:click={naviga_alla_rotta_principale}/>

          {#if richiudibile}
            <Bottone
                piccolo={true}
                variante="primario-invertito"
                on:click={chiudi_barra_laterale}>
              <Icona tipo="chiudi"/>
            </Bottone>            
          {/if}
        </div>

        {#if window.location.hostname == `portal.lab.hbenchmark.it` ||
            window.location.hostname == `hstaff.lab.hbenchmark.it`}
          <div class="segnale-sito-demo">
            {t.sito_demo[$l]}
          </div>
        {/if}

        <div class="contenuto">
          <slot/>
        </div>

        <div class="uscita">
          <Collegamento on:click={esci_da_hbenchmark}>
            {t.esci_da_hbenchmark[$l]}
          </Collegamento>
        </div>
      </div>
    {/if}
  </div>

  <svelte:window
      on:click={determina_se_aprire_barra_laterale}
      on:chiudi-barra-laterale={chiudi_barra_laterale}
      on:apri-barra-laterale={apri_barra_laterale}/>


<style>        
  :global(div#barra-laterale) {
    --padding-base: 16px;
    width:          258px;
    display:        flex;
    flex-direction: column;
    border-right:   1px solid var(--colore-bordo);
    overflow:       hidden;
    white-space:    nowrap;
    position:       fixed;
    top:            0;
    bottom:         0;
    left:           0;
    background:     var(--colore-bianco);
    z-index:        9;
    transition:     width 0.4s ease;
  }

  /* Chiusa. */
  :global(div#barra-laterale.chiusa) {
    width:          0;
    border:         none;
  }

  /* Logo. */
  :global(div#barra-laterale div.logo) {
    display:          flex;
    justify-content:  space-between;
    align-items:      center;
    padding:          var(--padding-base);
    margin-bottom:    40px;
  }
  :global(div#barra-laterale div.logo > img) {
    height:           40px;
    cursor:           pointer;
  }
  :global(div#barra-laterale div.logo > button) {
    padding:          3.5px;
  }
  :global(div#barra-laterale div.logo.senza-margini) {
    margin: 0px;
  }

  :global(div.segnale-sito-demo) {
    background-color: var(--colore-primario);
    color:            var(--colore-bianco);
    padding:          10px;
    font-size:        21px;
    text-transform:   uppercase;
    font-weight:      600;
    text-align:       center;
    margin-bottom:    8px;
  }

  /* Contenuto, dove staranno le rotte. */
  :global(div#barra-laterale div.transizione) {
    display:          flex;
    flex-direction:   column;
    flex-grow:        1;
  }
  :global(div#barra-laterale div.contenuto) {
    flex-grow:        1;
  }

  /* Uscita. */
  :global(div#barra-laterale div.uscita) {
    margin-top:       auto;
  } 
  :global(div#barra-laterale div.uscita a) {
    color:            var(--colore-secondario);
    font-weight:      normal;
    margin-bottom:    30px;
  } 
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Collegamento from "@/base/componenti/barra_laterale/Collegamento.svelte"
  import { fade } from "svelte/transition"
  import { utente_corrente } from "@/base/sorgenti/svuby"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import logo_predefinito from "@/base/immagini/logo-completo-semplice.svg"

  export let logo = null
  export let chiusa = false
  export let richiudibile = true

  if (logo == null) logo = logo_predefinito

  const t = {
    esci_da_hbenchmark: {
      it: `Esci`,
      en: `Logout`,
      de: `Ausloggen` },
    sito_demo: {
      it: `Demo`,
      en: ``,
      de: `` },
  }

  ////
  // Chiude la barra laterale.
  function chiudi_barra_laterale() {
    chiusa = true
    //window.dispatchEvent(new CustomEvent("barra-laterale-chiusa"))
  }
  ////
  // Apre la barra laterale.
  function apri_barra_laterale() {
    chiusa = false
  }

  // Propagazione eventi.
  function propaga_apertura() {
    window.dispatchEvent(new CustomEvent("barra-laterale-aperta"))
  }
  function propaga_chiusura() {
    window.dispatchEvent(new CustomEvent("barra-laterale-chiusa"))
  }

  ////
  // Esce dall'applicazione e cancella la sessione utente.
  function esci_da_hbenchmark() {
    
    if (window.dataLayer != null) window.dataLayer.push(function() {
      this.reset()
    })
    utente_corrente.slogga()
    window.dispatchEvent(new CustomEvent("logout"))
  }

  ////
  // Determina se aprire la barra laterale.
  function determina_se_aprire_barra_laterale(evento) {
    if (evento.target == null) return

    let sorgente = evento.target.closest("#apritore-barra-laterale")
    if (sorgente == null) return

    chiusa = false
  }

  ////
  // Naviga alla rotta principale.
  function naviga_alla_rotta_principale() {
    navigatore.verso("/")
  }
</script>
