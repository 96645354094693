
  <div
      class="contenitore-tabella"
      bind:this={elemento}>
    <div class="azioni-tabella">
      {#if esportazione_remota}
        <div class="esporta">
          <Bottone
              piccolo={true}
              variante="primario-invertito"
              on:click={esporta_in_csv_da_remoto}>
            {t.esporta[$l]}
          </Bottone>
        </div>
      {:else if esportabile}
        <div class="esporta">
          <Bottone
              piccolo={true}
              variante="primario-invertito"
              on:click={evento => esporta_in_csv(colonne, righe)}>
            {t.esporta[$l]}
          </Bottone>
        </div>
      {/if}
    </div>

    <table
        class:a-capo={a_capo}
        class:cliccabile={cliccabile}
        class:disabilitata={disabilitata}
        class:intestazione-fissa={intestazione_fissa}
        class:prima-colonna-fissa={prima_colonna_fissa}
        class:ultima-colonna-fissa={ultima_colonna_fissa}
        class:senza-evidenziazione={senza_evidenziazione}>
      <thead>
        {#if colonne.some(colonna => typeof colonna == "object")}
          <tr>
            {#each colonne as colonna}
              {#if typeof colonna == "object"}
                {#if dizionario.colonne != null && dizionario.colonne[Object.keys(colonna)[0]] != null}
                  <th colspan="{Object.values(colonna)[0].length}">
                    {dizionario.colonne[Object.keys(colonna)[0]][$l] || "-"}
                  </th>
                {:else}
                  <th colspan="{Object.values(colonna)[0].length}">{Object.keys(colonna)[0]}</th>
                {/if}
              {:else}
                {#if dizionario.colonne != null && dizionario.colonne[colonna] != null}
                  <th rowspan="2">{dizionario.colonne[colonna][$l] || "-"}</th>
                {:else}
                  <th rowspan="2">{colonna}</th>
                {/if}
              {/if}
            {/each}
          </tr>
          <tr>
            {#each colonne as colonna}
              {#if typeof colonna == "object"}
                {#each Object.values(colonna)[0] as sottocolonna}
                  {#if dizionario.colonne != null && dizionario.colonne[sottocolonna] != null}
                    <th>{dizionario.colonne[sottocolonna][$l] || "-"}</th>
                  {:else}
                    <th>{sottocolonna}</th>
                  {/if}
                {/each}
              {/if}
            {/each}
          </tr>
        {:else}
          <tr>            
            {#each colonne as colonna}
              {#if dizionario.colonne != null && dizionario.colonne[colonna] != null}
                <th>{dizionario.colonne[colonna][$l] || "-"}</th>
              {:else}
                <th>{colonna}</th>
              {/if}
            {/each}
          </tr>
        {/if}
      </thead>
      <tbody>
        {#if righe.length > 0}
          {#each righe as riga, indice_riga}
            <tr in:fade={{ duration: 500, delay: (indice_riga * 10) }}>
              {#each colonne as colonna, indice_colonna}
                {#if typeof colonna == "object"}
                  <slot></slot>
                {:else}
                  <td on:click={evento => propaga_click_riga(riga)}>
                    <slot
                        riga={riga}
                        colonna={colonna}
                        valore={riga[colonna]}
                        indice_riga={indice_riga}
                        indice_colonna={indice_colonna}>
                      {valore_cella(riga, colonna, riga[colonna], indice_riga, indice_colonna)}
                    </slot>
                  </td>
                {/if}
              {/each}
            </tr>
          {/each}
        {:else}
          <tr class="riga-senza-hover">
            <td class="nessun-dato" colspan="999">
              <em>{t.nessun_dato[$l]}</em>
            </td>
          </tr>
        {/if}
      </tbody>
    </table>
  </div>


<style>
  :global(div.contenitore-tabella) {
    flex-grow:  1;
    overflow:   auto;
  }

  /* Generali. */
  :global(div.contenitore-tabella table) {
    width:            100%;
    border-spacing:   0;
    border-color:     transparent;
    border-collapse:  collapse;
    position:         relative;
    white-space:      nowrap;
  }
  :global(div.contenitore-tabella table.disabilitata) {
    opacity:          0.5;
    pointer-events:   none;
  }
  :global(div.contenitore-tabella table.a-capo) {
    white-space:      normal;
  }
  :global(div.contenitore-tabella td.nessun-dato) {
    text-align:       center;
  }

  /* Azioni. */
  :global(div.contenitore-tabella > div.azioni-tabella) {
    display:          flex;
    align-items:      center;
  }
  :global(div.contenitore-tabella > div.azioni-tabella > div.esporta) {
    margin-left:      auto;
  }

  /* Tabella. */
  :global(div.contenitore-tabella table tbody tr) {
    background-color: var(--colore-bianco);
    border-bottom:    1px solid var(--colore-bordo);
  }
  :global(div.contenitore-tabella table tbody tr:not(.riga-senza-hover):hover) {
    background-color: var(--colore-sorvolo);
  }
  :global(div.contenitore-tabella table[senza_evidenziazione] tbody tr:hover) {
    background-color: inherit;
  }
  :global(div.contenitore-tabella table tbody tr td) {
    font-size:        14px;
    padding:          14px 16px;
    border-top:       none;
    background-color: inherit;
    line-height:      24px;
  }

  :global(div.contenitore-tabella table tbody tr td button) {
    font-size:      12px;
    padding-top:    0;
    padding-bottom: 0;
  }

  :global(div.contenitore-tabella table thead) {
    background-color: var(--colore-header-tabella);
  }
  :global(div.contenitore-tabella table thead th) {
    background-color: var(--colore-header-tabella);
    text-transform:   uppercase;
    text-align:       left;
    padding:          11.5px 16px;
    color:            var(--colore-testo-leggero);
    font-size:        14px;
    line-height:      17px;
  }

  /* Cliccabile. */
  :global(div.contenitore-tabella table.cliccabile tbody td) {
    cursor:   pointer;
  }

  /* Intestazione Fissa */
  :global(div.contenitore-tabella table.intestazione-fissa thead th) {
    position:     sticky;
    top:          -2px;
    z-index:      5;
  }
  :global(div.contenitore-tabella table.intestazione-fissa thead th:first-child) {
    z-index:      7;
  }

  /* Prima Colonna Fissa */
  :global(div.contenitore-tabella table.prima-colonna-fissa tbody td:first-child) {
    position:     sticky;
    left:         0;
    z-index:      5;
    box-shadow:   inset 4px 0 4px -2px rgba(0, 0, 0, 0.3);
  }

  :global(div.contenitore-tabella table.prima-colonna-fissa thead th:first-child) {
    position:     sticky;
    left:         0;
    z-index:      7;
    box-shadow:   inset 4px 0 4px -2px rgba(0, 0, 0, 0.3);
  }

  /* Ultima Colonna Fissa */
  :global(div.contenitore-tabella table.ultima-colonna-fissa tbody td:last-child) {
    position:     sticky;
    right:        0;
    z-index:      5;
    box-shadow:   inset 4px 0 4px -2px rgba(0, 0, 0, 0.3);
  }

  :global(div.contenitore-tabella table.ultima-colonna-fissa thead th:last-child) {
    position:     sticky;
    right:        0;
    z-index:      7;
    box-shadow:   inset 4px 0 4px -2px rgba(0, 0, 0, 0.3);
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import { fade } from "svelte/transition"
  import { createEventDispatcher } from "svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"

  export let a_capo                           = false
  export let disabilitata                     = false
  export let intestazione_fissa               = false
  export let prima_colonna_fissa              = false
  export let ultima_colonna_fissa             = false
  export let senza_evidenziazione             = false
  export let cliccabile                       = false
  export let esportazione_remota              = false
  export let esportabile                      = false
  export let avvia_esportazione               = false
  export let dizionario                       = {}
  export let righe                            = []
  export let colonne                          = []
  export let valore_cella                     = (riga, colonna) => riga[colonna] || "-"
  export let indirizzo_esportazione_remota    = null
  export let metodo                           = "GET"
  export let parametri                        = {}
  export let tipo_dato                        = null

  let elemento

  const propaga = createEventDispatcher()
  const t = {
    nessun_dato: {
      it: `Nessun dato trovato.`,
      en: `No data found.`,
      de: `Keine Daten gefunden.`
    },
    esporta: {
      it: `Esporta`,
      en: `Export`,
      de: `Exportieren`
    }
  }

  ////
  // Propaga il click della riga.
  function propaga_click_riga(riga) {
    propaga("click", { riga })
  }

  ////
  // Esporta in CSV da dati ricavati dal backend
  async function esporta_in_csv_da_remoto() {
    let risposta = await retro.chiama(metodo,
    indirizzo_esportazione_remota, parametri)

    let righe_da_esportare = risposta.contenuto[tipo_dato]

    esporta_in_csv(colonne, righe_da_esportare)
  }

  ////
  // Esporta in CSV.
  function esporta_in_csv(colonne, righe) {
    avvia_esportazione = false

    let contenuto_csv = []
    let colonne_esportate = []

    colonne_esportate = colonne.flatMap(colonna => {
      if (typeof colonna == "object"){
        let sottocolonne = []

        colonna[Object.keys(colonna)[0]].forEach(sottocolonna => {
          if (dizionario.colonne != null && dizionario.colonne[sottocolonna] != null)
            sottocolonne = [ ...sottocolonne, dizionario.colonne[sottocolonna][$l] ]
          else
            sottocolonne = [ ...sottocolonne, sottocolonna ]
        })

        return sottocolonne
      }else {
        if (dizionario.colonne != null && dizionario.colonne[colonna] != null)
          return dizionario.colonne[colonna][$l]
        else
          return colonna
      }
    })
    contenuto_csv.push(colonne_esportate.join(";"))

    righe.each(riga => {
      let riga_esportata
      if (colonne.some(colonna => typeof colonna == "object")){
        riga_esportata = colonne.flatMap(colonna => {
          let valore = valore_cella(riga, colonna, riga[colonna])
          if (typeof valore == "object") return valore.map(sottovalore => `"${sottovalore}"`)
          return `"${valore}"`
        })
      } else {
        riga_esportata = colonne.map(colonna => `"${valore_cella(riga, colonna, riga[colonna])}"`)
      }

      contenuto_csv.push(riga_esportata.join(";").replaceAll("\n", " ").replaceAll("\r", " "))
    })
    contenuto_csv = contenuto_csv.join("\r\n")

    scarica_esportazione(contenuto_csv, "export.csv", "text/csv;encoding:utf-8");
  }

  ////
  // Crea collegamento per scaricamento.
  function scarica_esportazione(contenuto, nome_archivio, tipo) {
    let a = document.createElement("a")
    tipo = tipo || "application/octet-stream"

    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([contenuto], { type: tipo }), nome_archivio)
    } else if (URL && "download" in a) { //html5 a[download]
      a.href = URL.createObjectURL(new Blob([contenuto], { type: tipo }))
      a.setAttribute("download", nome_archivio)
      document.body.append(a)
      a.click()
      a.remove()
    } else {
      window.open("data:application/octet-stream," + encodeURIComponent(contenuto)) // solo con data-url
    }
  }

  $: if (avvia_esportazione) {
    if (indirizzo_esportazione_remota != null)
      esporta_in_csv_da_remoto()
    else
      esporta_in_csv(colonne, righe)
  }
</script>
